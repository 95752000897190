<template>
<div>
    <confirm
    text="la imagen"
    emit="deleteImage"
    @deleteImage="deleteImage"
    :id="'delete-'+model_name+'-image'"
    toast="Imagen eliminada"></confirm>

	<div>
		<div
		v-if="model.image_url">
			<div
			class="j-center">
				<vue-load-image>
					<img
					slot="image"
					class="slide-img b-r-1 shadow m-b-10" 
					:src="image(model)">

			        <b-spinner
					slot="preloader"
			        variant="success"></b-spinner>

					<div slot="error">
						Imagen no encontrada
					</div>
				</vue-load-image>
			</div>

			<btn-loader
			size="sm"
			text="Eliminar imagen" 
			class="m-b-10"
			@clicked="setDelete"
			:loader="deleting"
			variant="outline-danger" />
			<b-button
			size="sm"
			block 
			variant="outline-primary"
			@click="uploadImage">
				Cambiar imagen
			</b-button>
		</div>
		<b-button
		v-else
		size="sm"
		block 
		variant="outline-primary"
		@click="uploadImage">
			Agregar imagen
		</b-button>

	</div>
</div>
</template>
<script>
import Confirm from '@/common-vue/components/Confirm'
import VueLoadImage from 'vue-load-image'
import { Carousel, Slide } from 'vue-carousel'
export default {
	props: ['model', 'prop', 'model_name'],
	components: {
		Confirm,
		VueLoadImage,
	    Carousel,
	    Slide,

	    BtnLoader: () => import('@/common-vue/components/BtnLoader')
	},
	computed: {
		actions() {
			if (this.model_name == 'user') {
				return ['auth/deleteImage']
			} 
			return [this.model_name+'/deleteImage']
		},
	},
	data() {
		return {
			deleting: false,
		}
	},
	methods: {
		uploadImage() {
			this.$emit('uploadImage')
		},
		setDelete() {
			this.$store.commit(this.model_name+'/setDeleteImage', this.model[this.prop.key])
			this.$bvModal.show('delete-'+this.model_name+'-image')
		},
		deleteImage() {
			this.deleting = true 
			this.$api.delete('/delete-current-image/'+this.model_name+'/'+this.model.id+'/'+this.prop.key)
			.then(res => {
				this.deleting = false 
				this.$store.commit(this.model_name+'/add', res.data.model)
				this.$bvModal.hide(this.model_name)
				this.$toast.success('Imagen eliminada')
			})
			.catch(err => {
				this.deleting = false 
				console.log(err)
				this.$toast.success('Errror al eliminar imagen')
			})
		}
	}
}
</script>
<style scoped lang="sass">
.images
	img 
		max-width: 100%
</style>